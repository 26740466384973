import icon1 from '../images/smBackgroundIcons/blogger-logo-transparent-white.png';
import icon2 from '../images/smBackgroundIcons/ello-logo-transparent-white.png';
import icon3 from '../images/smBackgroundIcons/facebook-logo-transparent-white.png';
import icon4 from '../images/smBackgroundIcons/flickr-logo-transparent-white.png';
import icon5 from '../images/smBackgroundIcons/instagram-logo-transparent-white.png';
import icon6 from '../images/smBackgroundIcons/linkedin-logo-transparent-white.png';
import icon7 from '../images/smBackgroundIcons/medium-logo-transparent-white.png';
import icon8 from '../images/smBackgroundIcons/periscope-logo-transparent-white.png';
import icon9 from '../images/smBackgroundIcons/pinterest-logo-transparent-white.png';
import icon10 from '../images/smBackgroundIcons/reddit-logo-transparent-white.png';
import icon11 from '../images/smBackgroundIcons/skype-logo-transparent-white.png';
import icon12 from '../images/smBackgroundIcons/snapchat-logo-transparent-white.png';
import icon13 from '../images/smBackgroundIcons/tumblr-logo-transparent-white.png';
import icon14 from '../images/smBackgroundIcons/twitter-logo-transparent-white.png';
import icon15 from '../images/smBackgroundIcons/vimeo-logo-transparent-white.png';
import icon16 from '../images/smBackgroundIcons/vk-logo-transparent-white.png';
import icon17 from '../images/smBackgroundIcons/wechat-logo-transparent-white.png';
import icon18 from '../images/smBackgroundIcons/whatsapp-logo-transparent-white.png';
import icon19 from '../images/smBackgroundIcons/wordpress-logo-transparent-white.png';
import icon20 from '../images/smBackgroundIcons/youtube-logo-transparent-white.png';

const images = [
	icon1,
	icon2,
	icon3,
	icon4,
	icon5,
	icon6,
	icon7,
	icon8,
	icon9,
	icon10,
	icon11,
	icon12,
	icon13,
	icon14,
	icon15,
	icon16,
	icon17,
	icon18,
	icon19,
	icon20,
];
function shuffle(a) {
	var j, x, i;
	for (i = a.length - 1; i > 0; i--) {
		j = Math.floor(Math.random() * (i + 1));
		x = a[i];
		a[i] = a[j];
		a[j] = x;
	}
	return a;
}

const backgroundIcons = () => {
	let totalImages = [];
	let count = 0;
	for (let index = 0; index < 60; index++) {
		totalImages.push(images[count]);
		count++;
		if (count >= images.length) {
			count = 0;
		}
	}

	return shuffle(totalImages);
};
export default backgroundIcons;
