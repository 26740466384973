import React, { Component } from 'react';
import Background from '../components/Background';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Logo } from '../images/magnesconnect-unset.svg';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100vw',
		height: '100vh',
		textAlign: 'center',
	},
	title: {
		fontFamily: 'Quattrocento Sans',
		color: 'white',
		paddingTop: '2rem',
		paddingBottom: '2rem',
	},
	Logo: {
		width: '80%',
		color: 'white',
		'& > *': {
			fill: 'white',
			height: '4rem',
			width: 'auto',
			padding: '0 0.2rem 0 0',
		},
	},
	slogan: {},
});

class ComingSoon extends Component {
	render() {
		const { classes } = this.props;
		return (
			<>
				<Background />
				<div className={classes.root}>
					<Logo className={classes.Logo} />
					<Typography className={classes.title} variant='h4'>
						Coming Soon!
					</Typography>
					<Typography className={classes.title} variant='h5'>
						Connect All, Share One
					</Typography>
				</div>
			</>
		);
	}
}

export default withStyles(styles)(ComingSoon);
