import React from 'react';
import bgIcons from './BackgroundItems';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
	let backgroundIcons = {};
	let images = bgIcons();
	for (let index = 0; index < images.length; index++) {
		backgroundIcons = {
			...backgroundIcons,
			[`backgroundIcon${index + 1}`]: {
				height: '2rem',
				width: 'auto',
				animation: `$rise 30s ${theme.transitions.easing.easeInOut} ${Math.random() * 30000}ms infinite`,
				opacity: 0,
			},
		};
	}

	return {
		background: {
			position: 'absolute',
			background: '#1faffc',
			height: '100vh',
			width: '100%',
			zIndex: '-1',
			overflow: 'hidden',
		},
		backgroundIcon: {
			height: '2rem',
			width: 'auto',
			animation: `$rise 30s ${theme.transitions.easing.easeInOut} infinite`,
			//opacity: 0,
		},
		...backgroundIcons,
		'@keyframes rise': {
			'0%': {
				transform: 'translateY(100vh)',
				opacity: 0.25,
			},
			'100%': {
				transform: ' translateY(0vh)',
				opacity: 0.25,
			},
		},
	};
});

const Background = () => {
	const classes = useStyles();

	let images = bgIcons();
	let iconsImgs = images.map((iconTemp, index) => {
		return <img key={index} src={iconTemp} className={classes[`backgroundIcon${index + 1}`]} alt='social icon' />;
	});

	return (
		<div className={classes.background}>
			<div className='backgroundIcons'>{iconsImgs}</div>
		</div>
	);
};

export default Background;
